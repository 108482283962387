import { useEffect, useState } from "react";
import styled from "styled-components";
import React from "react";
import ReactMarkdown from "react-markdown";
import example from "../eventmarkdown/example.md";
import reactWsCwa from "../eventmarkdown/react-ws-cwa.md";
import fineDining from "../eventmarkdown/fine-dining.md";
import jakob from "../eventmarkdown/lunch-lecture.md";
import dateIt from "../eventmarkdown/dateit-22.md";

const Modal = ({ show, title, label, close }) => {
	const [markdown, setMarkdown] = useState("");

	useEffect(() => {
		if (show) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "visible";
		}
	});

	const getCorrectMarkdown = (match) => {
		switch (match) {
			case "Utforska React med Cygni och Chalmers Women Association!":
			    return reactWsCwa;
			case "Fine Dining":
			    return fineDining;
			case "Lunchföreläsning - Ett år efter examen":
			    return jakob;
			case "DatE-IT 2022":
			    return dateIt;
			default:
				return example;
		}
	};

	useEffect(() => {
		fetch(getCorrectMarkdown(label))
			.then((res) => res.text())
			.then((text) => setMarkdown(text));
	}, [label]);

	return (
		<>
			{show && (
				<ModalContainer>
					<Content>
						<ModalHeader>
							<h1>{title}</h1>
							<IconButton onClick={() => close()}>
								<i className="fas fa-times"/>
							</IconButton>
						</ModalHeader>

						<div>
							<ReactMarkdown children={markdown} />
						</div>
					</Content>
				</ModalContainer>
			)}
		</>
	);
};

const ModalHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 3rem;
`;

const Content = styled.div`
	padding: 2rem;
`;

const IconButton = styled.button`
	display: inline-block;
	border: none;
	padding: 1rem 1.2rem 1rem 1.2rem;
	margin: 0;
	text-decoration: none;
	font-family: sans-serif;
	font-size: 1rem;
	cursor: pointer;
	text-align: center;
	border-radius: 50%;
	background-color: transparent;
	transition-timing-function: ease-in;
	transition: 0.2s;
	&:hover {
		background-color: #f0f0f0;
	}
`;

const ModalContainer = styled.div`
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: white;
	color: black;
`;

export default Modal;
