import styled from "styled-components";
import EventCard from "../components/EventCard";
import data from "../events.json";

const LandingPage = () => {
	return (
		<>
			<LandingPageHeaderImage />
			<Section>
				Här kan du som student hitta information om Cygnis kommande
				studentevenemang och workshops. Vi kommer att uppdatera hemsidan löpande
				med nya event som du kan anmäla dig till. Listan på event hittar du
				längre ner på sidan.
				<p>
					Mer information om Cygni hittar du på{" "}
					<a href="https://cygni.se">cygni.se</a>
				</p>
			</Section>

			<EventSection>
				{data.events.length === 0 ? (
					<div>Det finns inga planerade event just nu.</div>
				) : (
					data.events.map((x) => (
						<EventCard
							key={x.title}
							label={x.label}
							title={x.title}
							date={x.date}
							desc={x.desc}
							image={x.image}
						/>
					))
				)}
			</EventSection>

			<WorksSection>
				<WorkImage
					alt="programming-students"
					src="assets/images/working-together.png"
				/>
				<div>
					<h2>Såhär går det till</h2>
					<ol>
						<li>Gå in och anmäl dig via eventformuläret</li>
						<li>Tipsa gärna kompisar om eventet</li>
						<li>
							Vi kommer att kontakta dig med mer information och möteslänk (om
							online) när vi fått in alla anmälningar
						</li>
						<li>
							Se till att du är väl förberedd genom att läsa instruktionerna!
							Ibland är det installationer eller liknande som behöver göras
						</li>
						<li>Kom på eventet!</li>
						<li>
							Ge oss feedback - I slutet så kommer vi fråga vad ni tyckte och vi
							vill gärna att ni svarar så att vi kan förbättra framtida event
						</li>
					</ol>
				</div>
			</WorksSection>
		</>
	);
};

const LandingPageHeaderImage = styled.div`
	display: flex;
	height: 100vh;
	background-image: url("assets/images/cygni-cover.jpg");
	opacity: 0.8;

	background-position: center;
	background-size: cover;
`;

const Section = styled.div`
	padding: 5rem;
	text-align: left;

	@media only screen and (max-width: 40rem) {
		padding: 1rem;
	}
`;

const EventSection = styled(Section)`
	background: linear-gradient(
			0deg,
			rgb(202 186 191 / 84%) 0%,
			rgb(202 202 202 / 87%) 100%
		),
		url("assets/images/middle.jpg") no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const WorksSection = styled(Section)`
	background-color: #fff3f3;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 40rem) {
		flex-direction: column;
		text-align: center;
	}
`;

const WorkImage = styled.img`
	width: 20rem;
	margin-right: 2rem;

	@media only screen and (max-width: 40rem) {
		margin-right: 0;
	}
`;

export default LandingPage;
