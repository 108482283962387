import { useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";

const EventCard = ({ date, title, desc, label, image, imageDescription }) => {
	const [event, setEvent] = useState("No event found");
	const [show, setShow] = useState(false);

	const onMoreInfoClick = (setTitle) => {
		setEvent(setTitle);
		setShow(true);
	};

	return (
		<>
			<Modal
				show={show}
				label={label}
				title={event}
				close={() => setShow(false)}
			/>
			<Card>
				<StyledImage src={`assets/images/${image}`} alt={imageDescription ?? "Event image"} />
				<CardBody>
					<CardTitle>
						{title}
					</CardTitle>
					<CardDate>
						{date}
					</CardDate>
					<CardButton onClick={() => onMoreInfoClick(title)}>
						Klicka här för mer information
					</CardButton>
					<Description>{desc}</Description>
				</CardBody>
			</Card>
		</>
	);
};

const Card = styled.div`
	width: 20.5rem;
	background-color: white;
	color: black;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
	margin-top: 1rem;

	@media only screen and (min-width: 40rem) {
		margin: 1rem;
	}
`;

const Description = styled.div`
	margin: 1.5rem;
	font-size: 1rem;
`;

const StyledImage = styled.img`
	height: 15.3rem;
  object-fit: contain;
`;

const CardTitle = styled.h2`
	text-align: center;
	margin: 1rem 2rem 0;
`;

const CardDate = styled.p`
	text-align: center;
	padding: 0 2rem;
`;

const CardBody = styled.div`
	display: flex;
	flex-direction: column;
`;

const CardButton = styled.button`
	padding: 1rem;
	background-color: #000735;
	color: #fff;
	outline: none;
	border: none;
	position: relative;
	font-family: "Barlow Semi Condensed", sans-serif;
	font-size: 1.2rem;
	justify-self: flex-end;
	&:hover {
		background-color: #000a4d;
		cursor: pointer;
	}
`;

export default EventCard;
