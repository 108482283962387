import "./App.css";
import styled from "styled-components";
import LandingPage from "./pages/LandingPage";

function App() {
	return (
		<div className="App">
			<Header>
				<HeaderImage
					alt="cygni - part of accenture"
					src="cygni-logo.png"
				></HeaderImage>
				<HeaderText>Väst Studentevent</HeaderText>
			</Header>
			<LandingPage></LandingPage>
			<Footer>
				Cygni Väst - Studentevent
				<IconWrapper>
					<Icon href="https://www.linkedin.com/company/cygni/?originalSubdomain=se">
						<i className="fab fa-linkedin"></i>
					</Icon>
					<Icon href="https://www.facebook.com/lifeatcygni">
						<i className="fab fa-facebook-square"></i>
					</Icon>
					<Icon href="https://www.instagram.com/lifeatcygni/">
						<i className="fab fa-instagram-square"></i>
					</Icon>
				</IconWrapper>
				<CygniLink className="cygni-link" href="https://cygni.se">
					https://cygni.se
				</CygniLink>
			</Footer>
		</div>
	);
}

export default App;

const Footer = styled.div`
	color: #000000;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem;
	background-color: #fff;
`;

const IconWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const Icon = styled.a`
	margin: 0.5rem;
	font-size: 3rem;
	color: black;
	text-decoration: none;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 1rem 0 1rem;
`;

const HeaderImage = styled.img`
	width: 15rem;
	justify-self: left;
`;

const CygniLink = styled.a`
	text-decoration: none;
	color: black;
`;

const HeaderText = styled.div`
	font-weight: 600;
	font-size: 1.6rem;
	justify-self: center;
	color: #000735;

	@media (max-width: 50rem) {
		font-size: 1rem;
	}
`;
